import { Button, Drawer, DrawerProps, Typography } from "antd";
import { instructionsContentStyle } from "../styles";

const { Title, Link, Paragraph, Text } = Typography;

interface InstructionsProps extends DrawerProps {
    purpose: string
}

const Instructions: React.FC<InstructionsProps> = ({ open, onClose, purpose }: InstructionsProps) => {
    return (
        <Drawer open={open} onClose={onClose} size={"large"} style={instructionsContentStyle}>
            <Title>Instructions</Title>
            <Paragraph>
                Thank you for agreeing to participate in this dissertation research. <Text strong>This research investigates how
                    educators use algorithmic advice in combination with their professional knowledge and experience
                    during decision making.</Text>
            </Paragraph>
            <Paragraph>
                Once you enter the simulation, you will be presented with a 9th grader student profile. The
                student profile contains demographic, academic, behavioral, and self-assessment data. You will
                be asked to make an initial self-assessment for 10th grade science course placement and rate
                your decision confidence. There are three options for course placement: (1) Advanced Placement
                science, (2) Advanced Placement science with a required support course or tutor, (3) 10th grade
                general science. You will then rate your confidence level on a scale of 0%-100%.
                After you submit your initial decision, you will be shown the algorithmic recommendation for
                course placement. You will have the opportunity to revise your initial decision and/or
                confidence, if you so choose. {purpose}
            </Paragraph>
            <Paragraph>
                <Text strong>Note:</Text> The College Board's Advanced Placement (AP) courses are college-level courses and exams taken during high school.
            </Paragraph>
            <Paragraph>
                There are 20 trials total. You will watch a short 3 minute video halfway through the simulation. The video has audio.
                It is estimated that this simulation will take between 20 to 30 minutes. The simulation was designed to be completed
                on a desktop computer.
            </Paragraph>
            <Paragraph>
                If you have <Text italic>questions</Text> at any time about the study or the procedures, you may contact Jessa
                Henderson at her email <Link href="mailto:jessa_henderson@gwu.edu" target={"_blank"}>jessa_henderson@gwu.edu</Link>.
            </Paragraph>
            <Button type="primary" onClick={onClose}>Continue</Button>
        </Drawer>
    )
}

export default Instructions