import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'

import AuthProvider, { useAuth } from "./components/AuthProvider"
import Login from "./components/Login"
import { Consent } from "./components/Consent";
import Simulation from "./components/Simulation"
import NotFound from "./components/NotFound";
import Registration from "./components/Registration";

// import "./App.css";

const queryClient = new QueryClient()

const ProtectedRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { participant } = useAuth();
  if (participant.Id === "" || !('Id' in participant)) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const App: React.FC = () => {
  const { onLogout } = useAuth();
  const navigate = useNavigate();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>

        <Routes>
          <Route index element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/consent" element={
            <ProtectedRoute>
              <Consent
                handleYesConsent={() => navigate("/simulation")}
                handleNoConsent={() => {
                  onLogout();
                  navigate("/")
                }}
              />
            </ProtectedRoute>
          } />
          <Route path="/simulation" element={
            <ProtectedRoute>
              <Simulation />
            </ProtectedRoute>
          } />
          <Route path="*" element={<NotFound />} />
        </Routes>

      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
