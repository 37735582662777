export const simulationMainHeaderStyle = {
    color: "white",
    fontSize: "2em",
}

export const simulationProgressHeaderStyle = {
    backgroundColor: "white",
    padding: "24px",
    margin: "0px",
    height: "auto",
}

export const simulationRecommendationHeaderStyle = {
    backgroundColor: "white",
    height: "auto",
    fontSize: "medium",
}

export const simulationRecommendationSiderStyle = {
    backgroundColor: "white",
}

export const simulationPurposeHeaderStyle = {
    backgroundColor: "white",
    padding: "24px",
    margin: "0px",
    lineHeight: "1.5em",
    height: "auto",
    fontSize: "medium",
}