import React from "react"
import { Button, Space, Typography } from "antd";

import { consentMainStyle, commonCenterStyle } from "../styles";

const { Link, Paragraph, Text } = Typography;

interface ConsentProps {
    handleYesConsent: () => void
    handleNoConsent: () => void
}

const Consent: React.FC<ConsentProps> = ({ handleYesConsent, handleNoConsent }) => {
    return (
        <Space direction="vertical" style={commonCenterStyle}>
            <div style={consentMainStyle}>
                <Paragraph>
                    Thank you for participating in a virtual simulation on using <Text italic>algorithmic advice in course
                        recommendations</Text>. This is part of a dissertation research project being conducted by Jessa
                    Henderson, a doctoral candidate, under the advice of her dissertation chair Dr. Natalie Milman, a
                    professor of educational technology, at <Text italic>George Washington University</Text>.
                </Paragraph>
                <Paragraph>
                    This virtual simulation should take between 30 minutes to one hour to complete. Once you enter
                    the simulation, you will be presented with a student profile, asked to make an initial self-
                    assessment of course placement and rate your decision confidence. After you submit your initial
                    decision, you will be shown the algorithmic recommendation for course placement and will have
                    the opportunity to revise your initial decision and/or confidence. There are 20 trials and you will
                    watch a short 3 minute video halfway through the simulation. Your participation in this
                    research is <Text strong italic>voluntary</Text>. You are free to decline to answer any particular question you do not wish
                    to answer for any reason. You may end your participation at any time during the research, no
                    questions asked. Your responses to this simulation will be linked to your survey responses by a
                    non-identifiable code to protect confidentiality.
                </Paragraph>
                <Paragraph>
                    By completing both the survey and simulation, you are entered in a drawing for one of eight $30
                    Amazon gift cards. The drawing will take place at the end of the data collection period and
                    winners will be notified electronically via the email provided in the survey. There are no direct
                    benefits from participating in this research study beyond the drawing. However, your responses
                    may help us learn more about the way that teachers may or may not incorporate algorithmic
                    advice in their decision-making process. The risk of participating in the research is not expected
                    to exceed the risks encountered in daily life, but there is still a small risk of loss of
                    confidentiality as you provided an email address to receive the simulation link. The researcher
                    has taken data protection steps to keep this risk of loss of confidentiality minimal.
                </Paragraph>
                <Paragraph>
                    If you have <Text italic>questions</Text> at any time about the study or the procedures, you may contact Jessa
                    Henderson at her email <Link href="mailto:jessa_henderson@gwu.edu" target={"_blank"}>jessa_henderson@gwu.edu</Link>.
                </Paragraph>
                <Paragraph>
                    If you feel you have not been treated according to the descriptions in this form, or that your
                    rights as a participant in research have not been honored during the course of this project, or you
                    have any <Text italic>questions, concerns, or complaints that you wish to address to someone other than the
                        investigator</Text>, you may contact the George Washington University Institutional Review Board at
                    1922 F Street NW, 4 th Floor, Washington, DC 20052, or email at <Link href="mailto:ohrirb@gwu.edu">ohrirb@gwu.edu</Link>.
                </Paragraph>
            </div>
            <Paragraph>
                I have read the informed consent and agree to participate in a virtual simulation now.
            </Paragraph>
            <Space direction="horizontal">
                <Button type="primary" onClick={handleYesConsent}>Yes</Button>
                <Button type="primary" onClick={handleNoConsent}>No</Button>
            </Space>
        </Space>
    )
}

const Consent2: React.FC<ConsentProps> = ({ handleYesConsent, handleNoConsent }) => {
    return (
        <Space direction="vertical" style={commonCenterStyle}>
            <div style={consentMainStyle}>
                <Paragraph>
                    <Text strong>Informed Consent</Text>
                </Paragraph>
                <Paragraph>
                    <Text strong>IRB #NCR224167</Text>
                </Paragraph>
                <Paragraph>
                    Thank you for participating in a virtual simulation on <Text italic>using algorithmic advice in course
                        recommendations</Text>.
                    This is part of a dissertation research project being conducted by Jessa Henderson, a doctoral candidate,
                    under the advice of her dissertation chair Dr. Natalie Milman, a professor of educational technology,
                    at <Text italic>George Washington University</Text>.
                </Paragraph>
                <Paragraph>
                    This virtual simulation should take approximately 20 to 30 minutes to complete. Once you enter the simulation,
                    you will be asked a few general, non-identifiable questions about your experience in education. You will then
                    be presented with a 9th grade student profile, asked to make an initial self-assessment for 10th grade science
                    course placement, and rate your decision confidence. After you submit your initial decision, you will be shown
                    the algorithmic recommendation for course placement and will have the opportunity to revise your initial decision
                    and/or confidence. There are 20 trials and you will watch a short 3 minute video halfway through the simulation.
                    Your participation in this research is <Text italic strong>voluntary</Text>. You are free to decline to answer any
                    particular question you do not wish to answer for any reason. You may end your participation at any time during the
                    research, no questions asked.
                </Paragraph>
                <Paragraph>
                    There are <Text italic>no direct benefits</Text> from participating in this research study beyond the Prolific payment.
                    However, your responses may help us learn more about the way that educators may or may not incorporate algorithmic advice
                    in their decision-making process. The risk of participating in the research is not expected to exceed the risks
                    encountered in daily life, but there is still a small risk of loss of confidentiality as this survey is linked to
                    your individual Prolific ID. The researcher has taken data protection steps to keep this risk of loss of confidentiality
                    minimal in their own data, in addition to those steps taken by Prolific to protect your information on their platform.
                </Paragraph>
                <Paragraph>
                    If you have <Text italic>questions</Text> at any time about the study or the procedures, you may contact Jessa Henderson at
                    her email <Link href="mailto:jessa_henderson@gwu.edu" target={"_blank"}>jessa_henderson@gwu.edu</Link>.
                </Paragraph>
                <Paragraph>
                    If you feel you have not been treated according to the descriptions in this form, or that your rights as a participant in
                    research have not been honored during the course of this project, or you have any <Text italic>questions, concerns, or
                        complaints that you wish to address to someone other than the investigator</Text>, you may contact the George Washington
                    University Institutional Review Board at 1922 F Street NW, 4th Floor, Washington, DC 20052, or email
                    at <Link href="mailto:ohrirb@gwu.edu">ohrirb@gwu.edu</Link>.
                </Paragraph>
            </div>
            <Paragraph>
                I have read the informed consent and agree to participate in a virtual simulation now.
            </Paragraph>
            <Space direction="horizontal">
                <Button type="primary" onClick={handleYesConsent}>Yes</Button>
                <Button type="primary" onClick={handleNoConsent}>No</Button>
            </Space>
        </Space>
    )
}

export { Consent, Consent2 }