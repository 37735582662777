import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { Participant, Recommendation, StudentRecord, SurveyForm } from "../models";
import { Registration } from "../models/registration.model";

axios.defaults.baseURL = "https://api.dissertationsimulation.com/";
axios.defaults.headers["Content-Type"] = "application/json";

export const useApiLogin = () => useMutation(async (participant_code: string) => {
    axios.defaults.headers["Authorization"] = participant_code;
    try {
        const resp = await axios({
            url: "/participant",
            method: "GET",
        })
        return resp.data.data as Participant;
    } catch {
        return null;
    }
}, {
    mutationKey: "api.login",
});

export const useApiGetParticipant = (participant_code: string) => useQuery<Participant>("api.get.participant", async () => {
    axios.defaults.headers["Authorization"] = participant_code;
    return await axios({
        url: "/participant",
        method: "GET",
    }).then(resp => resp.data.data);
}, { enabled: !!participant_code });

export const useApiGetStudent = (id?: string) => useQuery<StudentRecord>("api.get.student", async () =>
    await axios({
        url: `/students/${id}`,
        method: "GET",
    }).then(resp => resp.data.data), { enabled: !!id }
);

export const useApiPutRecommendation = () => useMutation(async (data: Recommendation) => {
    try {
        const resp = await axios({
            url: `/recommendations`,
            method: "PUT",
            data: data
        });
        return resp.data;
    } catch {
        return null;
    }
}, {
    mutationKey: "api.put.recommenation",
});

export const useApiPutSurvey = () => useMutation(async (data: SurveyForm) => {
    try {
        const resp = await axios({
            url: `/surveys`,
            method: "PUT",
            data: data
        });
        return resp.data;
    } catch {
        return null;
    }
}, {
    mutationKey: "api.put.survey",
});

export const useApiPutRegistration = () => useMutation(async (data: Registration) => await axios({
    url: `/registration`,
    method: "PUT",
    data: data
}).then(resp => resp.data), {
    mutationKey: "api.put.registration",
});