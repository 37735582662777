import React, { ReactNode, createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Alert, Spin } from "antd";
import { useApiGetParticipant, useApiLogin } from "../hooks";
import { Participant } from "../models";
import { commonCenterStyle } from "../styles";
import axios from "axios";


type Auth = {
    participant: Participant
    onLogin(values: { participant_code: string, onLoginRoute: string }): void
    onLogout(): void
};

const emptyParticipant = {
    Id: "",
    purpose: 0,
    student_order: [],
    completed_recommendations: [],
    completed_survey: false
}

export const AuthContext = createContext<Auth>({
    participant: emptyParticipant,
    onLogin: () => { },
    onLogout: () => { },
});

export const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider: React.FC<{
    children: ReactNode,
}> = ({ children }) => {
    const navigate = useNavigate();
    const [displayAuthAlert, setDisplayAuthAlert] = useState(false);
    const [displayAuthSpinner, setDisplayAuthSpinner] = useState(false);
    const { mutateAsync } = useApiLogin();
    const [participant, setParticipant] = useState<Participant>(() => {
        const saved = localStorage.getItem("participant");
        if (saved) {
            const participant = JSON.parse(saved);
            axios.defaults.headers["Authorization"] = participant?.Id;
            return participant
        }
        return emptyParticipant;
    });

    const { status, data } = useApiGetParticipant(participant?.Id);

    useEffect(() => {
        if (status === "success") {
            setParticipant(data)
        }
    }, [status, data]);

    useEffect(() => {
        localStorage.setItem("participant", JSON.stringify(participant));
    }, [participant])

    const handleLogin = async (values: { participant_code: string, onLoginRoute: string }) => {
        setDisplayAuthSpinner(true);
        axios.defaults.headers["Authorization"] = values.participant_code;
        const data = await mutateAsync(values.participant_code);
        if (data) {
            setParticipant(data);
            navigate(values.onLoginRoute);
        } else {
            setDisplayAuthAlert(true);
            navigate("/");
        }
        setDisplayAuthSpinner(false);
    };

    const handleLogout = () => {
        setParticipant(emptyParticipant);
    };

    const handleClose = () => {
        setDisplayAuthAlert(false);
    }

    const value = {
        participant,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return (
        <>
            {
                displayAuthAlert ? <Alert
                    message="Invalid Participant Code"
                    description={<><p>Please ensure you the code you endered is the same as the code you received in your welcome email.</p><p>If the problem persists please respond directly to that email.</p></>}
                    type="error"
                    closable
                    showIcon
                    afterClose={handleClose}
                /> : <></>
            }
            <AuthContext.Provider value={value}>
                {displayAuthSpinner ? <Spin tip="Loading..." size="large">
                    <div style={commonCenterStyle} />
                </Spin> : children}
            </AuthContext.Provider>
        </>

    );
};

export default AuthProvider;