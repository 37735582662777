import React, { useEffect, useMemo, useState } from "react";
import { Alert, Card, FloatButton, FormInstance, Layout, Modal, Steps, Tooltip, Typography } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import YouTube from 'react-youtube';

import { useAuth } from "./AuthProvider";
import Instructions from "./Instructions";
import Recommendation, { RecommendationFormValues } from "./Recommendation";
import Survey from "./Survey";
import Student from "./Student"

import { useApiGetParticipant, useApiPutRecommendation } from "../hooks";
import { purposes, purposeVideos, StudentRecord } from "../models";
import { simulationMainHeaderStyle, simulationProgressHeaderStyle, simulationPurposeHeaderStyle, simulationRecommendationHeaderStyle, simulationRecommendationSiderStyle } from "../styles";
import ThankYou from "./ThankYou";

const { Content, Header, Sider } = Layout
const { Paragraph, Text } = Typography;

const initialStudent: StudentRecord = {
  "8th Math Grade": "",
  "8th Sci Grade": "",
  "9th GPA": "",
  "Algorithm Suggests": "",
  "Fav Subject": "",
  "Hours Per Day for Extra Curriculars": "",
  "How Often Late": "",
  "How Often Without Homework": "",
  "IEP": "",
  "Id": "",
  "Needs Sci for Future Career": "",
  "Race": "",
  "Self as Math Person": "",
  "Self as Sci Person": "",
  "Sex": "",
  "Student Plans to take AP": "",
  "Student Wants to take AP Sci": ""
}

const initialRecommendation = {
  confidence: 50, recommendation: -1
}

const Simulation: React.FC = () => {
  const { participant: { Id } } = useAuth();
  const { status, data } = useApiGetParticipant(Id)

  const [currentStudent, setCurrentStudent] = useState(-1);
  const [studentData, setStudentData] = useState(initialStudent);
  const [studentLoading, setStudentLoading] = useState(false);
  const [recBeforeAi, setRecBeforeAi] = useState(initialRecommendation)
  const [displayInstructions, setDisplayInstructions] = useState(false);
  const [displayAiRec, setDisplayAiRec] = useState(false);
  const [displayVideo, setDisplayVideo] = useState(false);
  const [videoWatched, setVideoWatched] = useState(false);
  const [surveyComplete, setSurveyComplete] = useState(false);
  const { mutateAsync } = useApiPutRecommendation();

  const next = () => {
    setCurrentStudent(currentStudent + 1);
  };

  const handleSubmission = async (form: FormInstance<RecommendationFormValues>) => {
    const { confidence, recommendation } = form.getFieldsValue();
    if (displayAiRec) {
      setStudentLoading(true);
      const rec = {
        student: studentData.Id,
        confidence_before: recBeforeAi.confidence,
        recommendation_before: recBeforeAi.recommendation,
        confidence_after: confidence,
        recommendation_after: recommendation,
        order: currentStudent,
        purpose: data?.purpose,
        video_watched: videoWatched
      };
      await mutateAsync(rec);
      form.setFieldsValue(initialRecommendation);
      setDisplayAiRec(false);
      setRecBeforeAi(initialRecommendation);
      next();
      setStudentLoading(false);
    } else {
      setRecBeforeAi({ confidence, recommendation });
      setDisplayAiRec(true);
    }
  };

  useEffect(() => {
    if (status === "success") {
      setCurrentStudent(data.completed_recommendations.length);
      setDisplayInstructions(data.completed_recommendations.length < 1)
    }
  }, [status, data])

  useEffect(() => {
    if (currentStudent === 10) {
      setDisplayVideo(true)
    }
  }, [currentStudent])

  const items = useMemo(() => data?.student_order.map((item) => ({ key: item, title: "" })), [data]);
  const purpose = useMemo(() => purposes[data?.purpose || 0], [data]);
  const purposeVideo = useMemo(() => purposeVideos[data?.purpose || 0], [data]);
  const studentID = useMemo(() => data?.student_order[currentStudent], [currentStudent]);

  return (
    <>
      <Layout>
        <Header style={simulationMainHeaderStyle}>
          Science Course Recommendations
        </Header>
        <Content
          style={{
            padding: 24,
            minHeight: "100vh",
            background: "white"
          }}
        >
          <Layout>
            <Header style={simulationProgressHeaderStyle} >
              <Steps current={currentStudent} items={items} />
            </Header>

            <Content>

              {currentStudent < 20 && (
                <Layout>
                  <Header style={simulationPurposeHeaderStyle}>
                    <Paragraph>
                      This research investigates how educators use algorithmic advice in combination with their professional knowledge and experience during decision making.
                    </Paragraph>
                    <Paragraph><Text strong>{purpose}</Text></Paragraph>
                  </Header>
                  <Layout>
                    <Content>
                      {
                        studentLoading || !studentID ? <Card style={{ borderRadius: 0, height: "100vh" }} title="Submitting recommendation..." bordered={true} loading={true} /> :
                          <Student
                            key={studentID}
                            student_id={studentID}
                            updateStudent={(data) => setStudentData(data)}
                          ></Student>
                      }
                    </Content>
                    <Sider style={simulationRecommendationSiderStyle} width={"35%"}>
                      <Layout>
                        <Header style={simulationRecommendationHeaderStyle}>
                          {
                            displayAiRec ?
                              <Alert message="Algorithmic Advice" description={
                                <>
                                  <Paragraph>{studentData["Algorithm Suggests"]}</Paragraph>
                                  <Paragraph><Text italic>Please submit your recommendation again, you may or may not alter your original decision and/or confidence.</Text></Paragraph>
                                </>
                              } type="info" showIcon /> :
                              <></>
                          }
                        </Header>
                        <Content>
                          <Recommendation key={data?.student_order[currentStudent]} handleSubmission={handleSubmission} />
                        </Content>
                      </Layout>

                    </Sider>
                  </Layout>
                </Layout>
              )}

              {currentStudent >= 20 && !(data?.completed_survey || surveyComplete) && <Survey onComplete={() => setSurveyComplete(true)}></Survey>}
              {currentStudent >= 20 && (data?.completed_survey || surveyComplete) && <ThankYou />}

            </Content>
          </Layout>
        </Content>
      </Layout >
      <Instructions open={displayInstructions} onClose={() => setDisplayInstructions(false)} purpose={purpose} />
      <Modal title="The simulation will resume after the video" open={displayVideo} closable={false} footer={null} centered={true} width={690}>
        <YouTube videoId={purposeVideo} onEnd={() => {
          setVideoWatched(true);
          setDisplayVideo(false);
        }}></YouTube>
      </Modal>
      <Tooltip title="Instructions">
        <FloatButton icon={<QuestionCircleOutlined />} type="primary" style={{ right: 94 }} onClick={() => setDisplayInstructions(true)} />
      </Tooltip>
    </>
  )
}

export default Simulation
