import { Result } from "antd";

const ThankYou: React.FC = () => {
    return (
        <Result
            status="success"
            title="Successfully Completed the Simulation"
            subTitle={`Prolific completion code: ${process.env.REACT_APP_PROLIFIC_COMPLETION_CODE}`}
        />
    )
}

export default ThankYou;