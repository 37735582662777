import React, { useEffect } from "react";
import { Card, List } from "antd";

import { StudentInfo, StudentRecord } from "../models";
import { useApiGetStudent } from "../hooks";

const DemographicInfo: StudentInfo[] = [
    {
        title: "Sex",
        key: "Sex",
    },
    {
        title: "Race",
        key: "Race",
    },
    {
        title: "IEP",
        key: "IEP",
    },
];

const AcademicInfo: StudentInfo[] = [
    {
        title: "9th grade GPA",
        key: "9th GPA",
    },
    {
        title: "8th math grade",
        key: "8th Math Grade",
    },
    {
        title: "8th science grade",
        key: "8th Sci Grade",
    },
    {
        title: "Students favorite subject",
        key: "Fav Subject",
    },
];

const BehaviorInfo: StudentInfo[] = [
    {
        title: "How often without homework",
        key: "How Often Without Homework",
    },
    {
        title: "How often late",
        key: "How Often Late",
    },
    {
        title: "Hours per day for extra curriculars",
        key: "Hours Per Day for Extra Curriculars",
    },
];

const SelfAssessmentInfo: StudentInfo[] = [
    {
        title: "Sees self as a math person",
        key: "Self as Math Person",
    },
    {
        title: "Sees self as a science person",
        key: "Self as Sci Person",
    },
    {
        title: "Needs science for their future job",
        key: "Needs Sci for Future Career",
    },
];

const ApInfo: StudentInfo[] = [
    {
        title: "Student has taken or plans to take an AP test",
        key: "Student Plans to take AP",
    },
    {
        title: "Student plans to take AP Science",
        key: "Student Wants to take AP Sci",
    },
];

interface StudentProps {
    student_id?: string,
    updateStudent: (data: StudentRecord) => void
}

const Student: React.FC<StudentProps> = ({ student_id, updateStudent }: StudentProps) => {
    const { isLoading, status, data = {} as unknown as StudentRecord } = useApiGetStudent(student_id);
    useEffect(() => { updateStudent(data) }, [status, data])

    return (
        <Card style={{ borderRadius: 0 }} title={`Student Profile: ${data["Id"] || ""}`} bordered={true} loading={isLoading}>
            <Card.Grid hoverable={false} >
                <Card title="Demographics" bordered={false} type="inner">
                    <List<StudentInfo>
                        style={{ width: "100%" }}
                        itemLayout="horizontal"
                        dataSource={DemographicInfo}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={data[item.key]}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Card.Grid>
            <Card.Grid hoverable={false} >
                <Card title="Academic Info" bordered={false} type="inner">
                    <List<StudentInfo>
                        style={{ width: "100%" }}
                        itemLayout="horizontal"
                        dataSource={AcademicInfo}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={data[item.key]}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Card.Grid>
            <Card.Grid hoverable={false} >
                <Card title="Behavior Info" bordered={false} type="inner">
                    <List<StudentInfo>
                        style={{ width: "100%" }}
                        itemLayout="horizontal"
                        dataSource={BehaviorInfo}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={data[item.key]}
                                />
                            </List.Item>
                        )}
                    />

                </Card>
            </Card.Grid>
            <Card.Grid hoverable={false} >
                <Card title="Self-assessment Info" bordered={false} type="inner">
                    <List<StudentInfo>
                        style={{ width: "100%" }}
                        itemLayout="horizontal"
                        dataSource={SelfAssessmentInfo}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={data[item.key]}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Card.Grid>
            <Card.Grid hoverable={false} >
                <Card title="AP Info" bordered={false} type="inner">
                    <List<StudentInfo>
                        style={{ width: "100%" }}
                        itemLayout="horizontal"
                        dataSource={ApInfo}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={item.title}
                                    description={data[item.key]}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Card.Grid>
        </Card>
    )
}

export default Student;