import React, { useEffect } from "react"
import { Button, Form, Input, Space } from "antd";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "./AuthProvider"

import { commonCenterStyle } from "../styles";

const Login: React.FC = () => {
    const { onLogin } = useAuth();
    const [searchParams] = useSearchParams();
    const participant_code = searchParams.get("participant_code");

    useEffect(() => {
        if (participant_code) {
            onLogin({ participant_code, onLoginRoute: "/consent" })
        }
    });

    return (
        <Space direction="vertical" style={commonCenterStyle} >
            <Form
                name="participant"
                onFinish={({ participant_code }) => onLogin({ participant_code, onLoginRoute: "/consent" })}
                autoComplete="off"
                layout='inline'
            >
                <Form.Item
                    name="participant_code"
                    rules={[{ required: true, message: "Participant code required." }]}
                >
                    <Input.Password placeholder="participant code" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Space>

    );
};

export default Login;