import React, { CSSProperties, useState } from 'react';
import { Button, Form, FormInstance, Radio, RadioChangeEvent, Slider, Space, Typography } from 'antd';
import { recommendationSliderStyle, recommendationSpaceStyle } from "../styles";

export type RecommendationFormValues = {
    confidence: number
    recommendation: number
}

const { Paragraph } = Typography;

const Recommendation: React.FC<{ handleSubmission: (form: FormInstance<RecommendationFormValues>) => void }> = ({ handleSubmission }) => {
    const initialValues = {
        confidence: -1, recommendation: -1
    }
    const [form] = Form.useForm()
    const [userRec, setUserRec] = useState<number | null>(null);
    const [confidenceStyle, setConfidenceStyle] = useState<CSSProperties>({ visibility: 'hidden' });

    const onRecChange = (e: RadioChangeEvent) => setUserRec(e.target.value);
    const onConfidenceChange = (_: number) => setConfidenceStyle({ visibility: 'visible' });

    return (
        <>
            <Space direction='vertical' style={recommendationSpaceStyle}>
                <Paragraph>Do you recommend this student for 10th Grade Advanced Placement science?</Paragraph>
                <Form
                    form={form}
                    onFinish={() => {
                        handleSubmission(form);
                    }}
                    initialValues={initialValues}
                >
                    <Form.Item label="Recommendation" name="recommendation" rules={
                        [
                            () => ({
                                validator(_, value) {
                                    if (value >= 0 && value <= 2) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Please select a recommendation'));
                                },
                            }),
                        ]}>
                        <Radio.Group onChange={onRecChange} value={userRec}>
                            <Space direction='vertical'>
                                <Radio value={2}>
                                    Yes
                                </Radio>
                                <Radio value={1}>
                                    Yes with a support course or tutor
                                </Radio>
                                <Radio value={0}>
                                    No
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Confidence" name="confidence" rules={
                        [
                            () => ({
                                validator(_, value) {
                                    if (value >= 0 && value <= 100) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Pleased rate your confidence on a scale of 0-100'));
                                },
                            }),
                        ]}>
                        <Slider
                            style={recommendationSliderStyle}
                            handleStyle={confidenceStyle}
                            min={-1}
                            max={100}
                            marks={{ 0: '0%', 20: '20%', 40: '40%', 60: '60%', 80: '80%', 100: '100%' }}
                            onChange={onConfidenceChange}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
        </>
    )
}

export default Recommendation;