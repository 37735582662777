import React from "react";
import { Button, Form, Input, Layout } from "antd";
import { useApiPutSurvey } from "../hooks";
import { SurveyForm } from "../models";

const { Content } = Layout
const { TextArea } = Input;

interface SurveyProps {
    onComplete?: () => void
}

const Survey: React.FC<SurveyProps> = ({ onComplete }) => {
    const { mutateAsync } = useApiPutSurvey();
    const [form] = Form.useForm();

    const handleSubmission = async (data: SurveyForm) => {
        await mutateAsync(data)
        if (!!onComplete) {
            onComplete()
        }

    };

    return (
        <Layout>
            <Content style={{ background: "white", padding: "16px" }}>
                <Form<SurveyForm>
                    layout="vertical"
                    form={form}
                    onFinish={handleSubmission}
                >
                    <Form.Item
                        name="question_1"
                        label="What concerns, if any, do you have regarding using algorithmic advice as a part of your decision-making processes in your job?"
                    >
                        <TextArea placeholder="Open ended (not required)" rows={8} />
                    </Form.Item>
                    <Form.Item
                        name="question_2"
                        label="What are you excited about, if anything, regarding using algorithmic advice as a part of your decision-making process in your job?"
                    >
                        <TextArea placeholder="Open ended (not required)" rows={8} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    )
};

export default Survey;