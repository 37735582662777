import React, { useRef, useState } from "react"
import { Button, Checkbox, Form, Image, Input, InputNumber, Modal, Radio, Result, Space, Spin } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { commonCenterStyle } from "../styles";
import { Registration as RegistrationModel, RegistrationForm } from "../models/registration.model";
import { Consent2 } from "./Consent";
import { useApiPutRegistration } from "../hooks";
import { useAuth } from "./AuthProvider"


const Registration: React.FC = () => {
    const { onLogin } = useAuth();
    const captchaRef = useRef<any>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isCaptcha, setIsCaptcha] = useState<boolean>(false);
    const [isConsent, setIsConsent] = useState<boolean>(false);
    const [isLevelsOther, setIsLevelsOther] = useState<boolean>(false);
    const [isSubjectsOther, setIsSubjectsOther] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const worker = searchParams.get("PROLIFIC_PID");
    const { mutateAsync } = useApiPutRegistration();
    return (
        isError ?
            <Result
                status={"error"}
                title="Registration Failed"
                subTitle="You may not eligable to participate in this simulation. If you believe you are eligable please try again or contact..."
            />
            :
            <Space style={commonCenterStyle} >
                <Spin size="large" tip="Submitting..." spinning={isSubmitting} >
                    <Space direction="vertical">
                        <h1>Demographic Survey</h1>
                        <Form<RegistrationForm>
                            layout="vertical"
                            onFinish={(values) => {
                                setIsSubmitting(true)
                                const data: RegistrationModel = {
                                    ...values,
                                    ...{
                                        worker,
                                        recaptcha_response: captchaRef?.current?.getValue() as string
                                    },
                                }
                                mutateAsync(data).then(({ participant }) => {
                                    setIsSubmitting(false);
                                    onLogin({ participant_code: participant, onLoginRoute: "/simulation" });
                                }).catch(() => {
                                    setIsSubmitting(false);
                                    setIsError(true);
                                });
                            }}
                        >
                            <Form.Item name={['gender']} label='What is your gender identity?' rules={[{ required: true }]}>
                                <Radio.Group>
                                    <Radio value={'male'}>
                                        Male
                                    </Radio>
                                    <Radio value={'female'}>
                                        Female
                                    </Radio>
                                    <Radio value={'non-binary'}>
                                        Non-binary
                                    </Radio>
                                    <Radio value={'prefer not to say'}>
                                        Prefer not to say
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name={['age']} label='What is your age?' rules={[{ type: 'number', min: 0, max: 99, required: true }]}>
                                <InputNumber />
                            </Form.Item>
                            <Form.Item name={['region']} label='Which region of the United States do you live in?' rules={[{ required: true }]}>
                                <Space direction="vertical">
                                    <Image src="/images/region.gif"></Image>
                                    <Radio.Group>
                                        <Radio value={'northeast'}>
                                            Northeast
                                        </Radio>
                                        <Radio value={'midwest'}>
                                            Midwest
                                        </Radio>
                                        <Radio value={'south'}>
                                            South
                                        </Radio>
                                        <Radio value={'west'}>
                                            West
                                        </Radio>
                                    </Radio.Group>
                                </Space>
                            </Form.Item>
                            <Form.Item name={['levels']} label='What level do you or did you teach? (Select all that apply)' rules={[{ required: true }]}>
                                <Checkbox.Group
                                    options={['Middle School', 'High School', 'Higher Education', 'Other']}
                                    onChange={(checkedValues) => checkedValues.includes("Other") ? setIsLevelsOther(true) : setIsLevelsOther(false)}
                                />
                            </Form.Item>
                            {
                                isLevelsOther ?
                                    <Form.Item name={['levels_other']} rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item> : null
                            }
                            <Form.Item name={['subjects']} label='What subject(s) do you or did you teach? (Select all that apply)' rules={[{ required: true }]}>
                                <Checkbox.Group
                                    options={['Science', 'Technology', 'Engineering', 'Math', 'English', 'Social Studies', 'Other']}
                                    onChange={(checkedValues) => checkedValues.includes("Other") ? setIsSubjectsOther(true) : setIsSubjectsOther(false)}
                                />
                            </Form.Item>
                            {
                                isSubjectsOther ?
                                    <Form.Item name={['subjects_other']} rules={[{ required: true }]}>
                                        <Input></Input>
                                    </Form.Item> : null
                            }
                            <Form.Item name={['experience']} label='How many years do you have of teaching experience?' rules={[{ type: 'number', min: 0, max: 99, required: true }]}>
                                <InputNumber />
                            </Form.Item>
                            <Form.Item name={['recommendations']} label='Have you made course recommendations for students before?' rules={[{ required: true }]}>
                                <Radio.Group>
                                    <Radio value={'yes'}>
                                        Yes
                                    </Radio>
                                    <Radio value={'no'}>
                                        No
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name={['internship']} label='If you are a teacher in training, are you currently in or have you completed your teaching internship? If you are not currently a teacher in training, please mark not applicable.' rules={[{ required: true }]}>
                                <Radio.Group>
                                    <Radio value={'yes'}>
                                        Yes
                                    </Radio>
                                    <Radio value={'no'}>
                                        No
                                    </Radio>
                                    <Radio value={'not applicable'}>
                                        Not applicable
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name={['algorithm']} label='How certain are you that you know what an algorithm is?' rules={[{ required: true }]}>
                                <Radio.Group>
                                    <Radio value={'I am certain that I do NOT know'}>
                                        I am certain that I do NOT know
                                    </Radio>
                                    <Radio value={'Not at all certain that I know'}>
                                        Not at all certain that I know
                                    </Radio>
                                    <Radio value={'Somewhat certain that I know'}>
                                        Somewhat certain that I know
                                    </Radio>
                                    <Radio value={'Fairly certain that I know'}>
                                        Fairly certain that I know
                                    </Radio>
                                    <Radio value={'Extremely certain that I know'}>
                                        Extremely certain that I know
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || "none"}
                                    ref={captchaRef}
                                    onChange={(token) => token ? setIsCaptcha(true) : setIsCaptcha(false)}

                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={!isCaptcha}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form >
                        <Modal open={!isConsent} width={"100%"} closable={false} footer={null}>
                            <Consent2 handleNoConsent={() => { navigate("/goodbye") }} handleYesConsent={() => { setIsConsent(true) }} />
                        </Modal>
                    </Space>
                </Spin>
            </Space >
    )
}

export default Registration